<template>
  <div class="record">
    <headbar :title="$t('认购记录')"></headbar>

    <div class="invest">
      <van-list v-if="list.length > 0" v-model:loading="loading" :finished="finished" :immediate-check="false" offset="10"
        @load="onLoad">
        <div class="invest-item" v-for="item in list" :key="item.id">
          <div class="cell">
            <div>{{ item.createTime2 }}</div>
            <div style="color: #e02020;">{{ item.state == 2 ? item.stateName + '：' + item.endTime2 : $t(item.stateName) }}</div>
          </div>
          <div class="cell">
            <div>{{ $t('数量') }}：{{ item.amountUsdt }}</div>
            <div>{{ $t('静态释放') }}：{{ item.shouyiStatic }}</div>
          </div>
          <div class="cell">
            <div>{{ $t('比例') }}：{{ item.shouyilv }}% ({{ item.shouyiDay }})</div>
            <div>{{ $t('动态加速释放') }}：{{ item.shouyiActive }}</div>
          </div>
          <div class="cell">
            <div>{{ $t('出局倍率') }}：{{ item.outMultiple }} ({{ item.outUsdt }})</div>
            <div>{{ $t('总收益') }}：{{ item.shouyiTotal }}</div>
          </div>
          <div class="cell" v-for="(transactionHash, transactionHash_index) in item.transactionHashList" :key="transactionHash_index">
            <div>{{ $t('交易哈希') }}：</div>
            <div>
              {{ abbr(transactionHash, 3, 3) }}
              <q-icon name="content_copy" style="font-size: 16px;" color="white" @click="copy(transactionHash)" />
            </div>
          </div>
          <div class="cell-progress">
            <van-progress :percentage="item.shouyiTotal / item.outUsdt * 100"
              :pivot-text="`${item.shouyiTotal}/${item.outUsdt}`" color="#e56efa" track-color="#585163"
              :stroke-width="30" />
          </div>
          <div class="cell-btn">
            <div class="btn" @click="tuikuan(item.id)"
              v-if="item.shouyiTotal < item.amountUsdt && item.state == 0 || item.state == 1">{{ $t('撤单') }}</div>
          </div>
        </div>
      </van-list>

      <empty v-if="list.length == 0"></empty>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { List, Progress } from "vant";
import useClipboard from 'vue-clipboard3';
import CustomComponent from "@/components/CustomComponent";

export default {
  name: '',
  components: {
    [List.name]: List,
    [Progress.name]: Progress,
  },
  setup() {
    return {
      us: ref(null),
      list: ref([]),
      pageIndex: ref(1),
      loading: ref(false),
      finished: ref(false),
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    // console.log(this.us)
    if (this.us) {
      this.getdata()
    }
  },
  methods: {
    onLoad() {
      this.loading = true
      this.pageIndex++
      this.getdata()
    },
    tolink(path) {
      this.$router.push(path)
    },
    getdata() {
      let _this = this
      _this.$request.post(
        "api/TouziOrder/List",
        {
          userid: _this.us.userid,
          uid: _this.us.id,
          pageIndex: _this.pageIndex,
          pageSize: 10,
          state: 99
        },
        (res) => {
          _this.loading = false
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
          }
          let data = res.data.data
          if (data.length == 0) {
            _this.finished = true
          } else {
            _this.finished = false
          }
          console.log(data)
          _this.list.push(...data)
        }
      )
    },
    tuikuan(id) {
      let _this = this
      this.$q.dialog({
        component: CustomComponent,
        componentProps: {
          messages: ["未回本撤出合约，只返回55%USDT，", "确定撤出吗?"],
          showCancel: true,
          persistent: true,
        },
      }).onOk(() => {
        this.$q.loading.show({
          message: _this.$i18n.t("撤单中...")
        })
        _this.$request.post(
          "api/TouziOrder/Tuikuan",
          {
            
            userid: _this.us.userid,
            touziOrderId: id
          },
          (res) => {
            _this.$q.loading.hide();
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
            if (res.data.code == 100) {
              _this.pageIndex = 1
              _this.loading = false
              _this.finished = false
              _this.list = []
              _this.getdata()
            }
          }
        )
      })
    },
    copy(val) {
      let that = this
      const { toClipboard } = useClipboard()
      try {
        toClipboard(val)
        that.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ['Replicating Success'],
            persistent: true,
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    abbr(val, start, end) {
      if (val && val.length > 20) {
        let newVal = val.replace(
          val.substring(start, val.length - end),
          "****"
        )
        return newVal
      } else {
        return val
      }
    },
  }
}
</script>

<style scoped>
.record {
  padding: 10px;
  min-height: 100vh;
  background-color: #080535
}

.invest-item {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  background-color: rgb(45, 38, 56);
}

.cell {
  padding: 2px 0;
  display: flex;
  justify-content: space-between;
}

.cell-btn {
  display: flex;
  justify-content: flex-end;
}

.btn {
  margin: 8px 0 0 10px;
  padding: 3px 15px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  background: linear-gradient(90deg, #f56ef9 0, #8a6dff);
  border-radius: 6px;
}

.cell-progress {
  padding: 15px 0 10px;
}

.cell-progress /deep/ .van-progress {
  overflow: hidden;
  border-radius: 20px;
}

.cell-progress /deep/ .van-progress__pivot {
  display: flex;
  align-items: center;
  border-radius: 0;
  height: 50px;
  font-size: 1rem;
}

.cell-progress /deep/ .van-progress__portion {
  border-radius: 0;
}
</style>